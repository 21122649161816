<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
  
  <v-row>
    <v-row no-gutters>
      <v-col cols="2"></v-col>
        <v-col cols="12">
          <v-row no-gutters>
            <v-col  v-for="(item, i) in qr" :key="i" cols="4" >
              <v-card :color="getBadge(item.status)" dark style="margin-left: 10px;height:180px">
                  <v-card-title class="text-h3">
                  {{item.codigocartao}}
                  </v-card-title>
                  <v-card-title class="text-h5">
                  Nº do Ticket: {{item.ticket}}
                  </v-card-title>
                  <v-card-subtitle>
                                    <br/>
                                    Responsável: {{item.responsavel}}<br/>
                                    Vencimento: {{currentDateTime(item.datavencimento)}}<br/>  
                                    Disponível: R$ {{ formatPrice( item.disponivel)}}<br/>  
                                    {{ getStatus(item.status)}}
                  </v-card-subtitle>

              
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      <v-col cols="3"></v-col>
    </v-row>
</v-row>
</v-container>
</template>

<script>
  import Cartao from '../../../services/cartao'
  import moment from 'moment'


  export default {
    name: 'DashboardDashboard',

    created() {
          if(this.$store.state.auth.status.loggedIn == false){
            this.$router.push('/')
          }
    },

    mounted () {
      this.listar()
    },

    data: () => ({
        qr:[],
    }),

    methods: {
      listar(){
            Cartao.lista_qr_dash().then(response => {
                  this.qr = response.data
              }).catch(e => {
              }).finally(() => {
            })
      },


    getBadge (status) {
      switch (status) {
         case true: return 'green lighten-1'
        case false: return 'blue-grey lighten-1'
        default: 'success'
      }
      },

      getStatus(status) {
      switch (status) {
        case true: return 'Ativo'
        case false: return 'Inativo'
        default: ''
      }
    },
     formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    currentDateTime(date) {
      if(date != null){
         return moment(date).format('DD/MM/YYYY HH:mm:SS')
      }else{
        return ''
      }
     
    },


    },
  }
</script>
