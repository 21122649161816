import { http } from './config'
import authHeader from './auth-header';

export default {

    lista: () => {
        return http.get('lista_cartao' , { headers: authHeader() })
    },

    listacodigos: (situacao) => {
        return http.get('listacodigos/' + situacao , { headers: authHeader() })
    },

    listaStatus: (status) => {
        return http.get('lista_cartao_status/'+status , { headers: authHeader() })
    },

    lista_cartao_periodo: (periodo) => {
        return http.post('lista_cartao_periodo', periodo , { headers: authHeader() })
    },


    relatorio_sintetico: (periodo) => {
        return http.post('atendimento_caixa_periodo', periodo , { headers: authHeader() })
    },
    

    mudarStatus: (card) => {
        return http.post('status_cartao', card , { headers: authHeader() })
    },

    relatorio_cartao: (n_consulta, periodo) =>{
        return http.post('relatorio_cartao/'+ n_consulta, periodo , { headers: authHeader() })
    },

    relatorio_cartao_ticket: (n_consulta) =>{
        return http.get('relatorio_cartao_ticket/'+ n_consulta , { headers: authHeader() })
    },

    lista_qr_dash: () =>{
        return http.get('listaqrcodes/', { headers: authHeader() })
    },
    
    visualizar: (cod) => {
        return http.get('cartao/'+ cod , { headers: authHeader() })
    },

    criarCartao: (card) => {
        return http.post('novo_cartao', card, { headers: authHeader() })
    },

    update_cartao: (card) => {
        return http.post('update_cartao', card, { headers: authHeader() })
    },

    
   

}
